<template>
	<section class="domain_content">
		<!-- privacy policy -->
		<div class="sub-page">
			<h1>Privacy Policy</h1>
			<p class="small">Last updated: Oct 10, 2024</p>
			<p>Please read the Privacy Policy carefully before using Our Service.</p>
			<p>
				This Privacy Policy for <a href="/">https://waproapk.com/</a> outlines how we collect, use, and safeguard your personal information when you interact with our website or any associated services that reference this policy. Your privacy is important to us, and we take full responsibility for how your data is managed. If you disagree with any aspect of this policy, we recommend refraining from using our services.
			</p>
			<div class="part">
				<h2>
					What Personal Information Do We Collect?
				</h2>
				<p>
					The information we collect depends on your interaction with our website and services. This may include personal details such as your name, contact information, browsing activity, or device-related data. The extent of the information gathered is determined by the services you use and the choices you make while engaging with our site.
				</p>
				<h2>
					Do We Process Sensitive Information?
				</h2>
				<p>
					We do not process any sensitive personal data, such as information about your racial or ethnic background, sexual orientation, or religious beliefs. Our data collection practices are strictly limited to non-sensitive information in line with applicable legal standards.
				</p>
				<h2>
					Do We Collect Information from Third Parties?
				</h2>
				<p>
					We do not obtain personal information from any third-party sources. All the data we collect comes directly from your interactions with our website and services.
				</p>
				<h2>
					How Do We Use Your Information?
				</h2>
				<p>
					The information we collect is used to enhance your experience with our services. This includes maintaining and improving the functionality of the site, communicating with you, strengthening security measures, and preventing fraud. We ensure that your data is processed only for legitimate and lawful purposes, and we handle it responsibly.
				</p>
				<h2>
					How Do We Protect Your Information?
				</h2>
				<p>
					We employ industry-standard security measures to safeguard your personal information. However, please note that no online system or data storage method can be completely secure. While we strive to protect your data, we cannot guarantee absolute protection from unauthorized access or cyber threats. If you have any concerns or require assistance, don't hesitate to reach out to us.
				</p>
			</div>
		</div>
	</section>
</template>
  
  <script>
import '@/css/main.scss'
const title = 'Privacy Policy'
const description = 'Privacy policies are here for you to learn more about the usage of your information.'
export default {
	metaInfo: {
		title,
		meta: [
			{
				name: 'description',
				content: description,
			},
			{
				name: 'title',
				content: title,
			},
			{
				property: 'og:title',
				content: title,
			},
			{
				property: 'og:description',
				content: description,
			},
		],
            "link": [{
                "rel": "canonical",
                "href": "https://waproapk.com/privacy/"
            }]
	},
}
</script>
  